
export default {
  name: 'ButtonBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    href() {
      if (!this.blok.route) return
      return this.blok.route
        .replace('{baseURL}', this.$config.baseURL)
        .replace('{appURL}', this.$config.appURL)
        .replace('{url}', this.$config.url)
    },
    bind() {
      return {
        [this.blok.size || 'regular']: true,
        disabled: !!this.blok.disabled,
        outlined: !!this.blok.outlined,
        rounded: !!this.blok.round,
        color: this.blok.theme || 'primary',
        href: this.href,
      }
    },
    classes() {
      const classes = {}
      if (this.blok.padding) {
        classes[`px-${this.blok.padding}`] = true
      }
      if (this.blok.color === "Gradient") {
        classes.gradient = true
      }
      return classes
    },
  },
}
